<template>
  <v-card>
    <v-card-title>
      Contratos
    </v-card-title>

    <!-- filter quicksearch -->
    <v-card-text class="d-flex align-center flex-wrap mt-5 pb-0">
      <!-- search -->
      <v-text-field
        v-model="filterOptions.client_name"
        label="Buscar Cliente"
        outlined
        hide-details
        dense
        class="user-search mb-4"
        md="2"
      >
      </v-text-field>

      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="openModal"
        >
          <v-icon>{{ icons.mdiFilter }}</v-icon>
          <span>Filtrar</span>
        </v-btn>

        <v-btn
          color="secondary"
          outlined
          class="mb-4"
          @click="filterReset"
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiFilterRemove }}
          </v-icon>
          <span>Limpar</span>
        </v-btn>
      </div>
    </v-card-text>

    <!--table-->
    <v-data-table
      :headers="headers"
      :items="contractList"
      :loading="loading"
      loading-text="Carregando dados..."
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      fixed-header
      :height="heightDinamic"
      hide-default-footer
      disable-sort
      @page-count="pageCount = $event"
    >
      <!-- action (edit, delete, etc) -->
      <template #[`item.actions`]="{ item }">
        <v-icon
          v-if="item.id === loadModal? false : true"
          medium
          class="me-2"
          @click="getContractInfo(item)"
        >
          {{ icons.mdiEyeOutline }}
        </v-icon>
        <v-progress-circular
          v-if="item.id === loadModal? true : false"
          indeterminate
          :size="20"
          color="gray"
        ></v-progress-circular>
      </template>

      <!--data empty-->
      <template v-slot:no-data>
        {{ noDataText }}
      </template>

      <!--field phone_cell-->
      <template #[`item.phone`]="{item}">
        <v-icon
          medium
          @click="chatWhatsapp(item.phone)"
        >
          {{ icons.mdiWhatsapp }}
        </v-icon>
        {{ item.phone }}
      </template>
    </v-data-table>

    <v-divider class="mt-4"></v-divider>

    <!-- pagination -->
    <v-card-text class="pt-2 pb-1">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-center"
        >
          <v-pagination
            v-model="pageOptions.page"
            total-visible="6"
            :length="pageOptions.pageCount"
            @input="updatePage"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="dialogFilter"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Filtrar</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filterOptions.date_bordero"
                      dense
                      label="Data do Bordero"
                      hide-details
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterOptions.date_bordero"
                    type="month"
                    width="270"
                    locale="pt-BR"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-autocomplete
                  v-model="filterOptions.vehicle_model"
                  :items="listVehicles[0]"
                  item-text="vehicle_model"
                  item-value="vehicle_model"
                  dense
                  label="Modelo do Veículo"
                  hide-details
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-autocomplete
                  v-model="filterOptions.contract_status"
                  dense
                  :items="statusList"
                  outlined
                  hide-details
                  label="Status"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-autocomplete
                  v-model="filterOptions.due_day"
                  dense
                  :items="due_dayList"
                  outlined
                  chips
                  hide-details
                  small-chips
                  multiple
                  label="Vencimento"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="error"
            @click="dialogFilter = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            outlined
            color="success"
            @click="filterContract"
          >
            Filtrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modal"
      width="900"
    >
      <contract-modal
        v-if="contractItem"
        :id-contract="idContract"
        :contract="contractItem"
        :modal="modal"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@axios'
import {
  mdiAccountOutline,
  mdiCalendar,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterMenu,
  mdiFilterRemove,
  mdiLoading,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiWhatsapp,
} from '@mdi/js'
import contractModal from './contractModal.vue'

export default {
  components: {
    contractModal,
  },
  data: () => ({
    listVehicles: [],
    dialogFilter: false,
    dialog: false,
    dialogDelete: false,
    icons: {
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiPencilOutline,
      mdiFileDocumentOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiExportVariant,
      mdiAccountOutline,
      mdiFilter,
      mdiFilterRemove,
      mdiWhatsapp,
      mdiCalendar,
      mdiFilterMenu,
      mdiLoading,
      mdiEyeOutline,
    },

    /* filter */
    filterOptions: {
      client_name: '',
      date_bordero: '',
      vehicle_model: '',
      contract_status: '',
      due_day: '',
    },
    dateMenu: false,
    due_dayList: ['20', '18', '15', '11', '1'],
    statusList: [
      'CANCELADO',
      'CONTEMPLADO',
      'DESISTENCIA GRUPO',
      'JURIDICO',
      'NORMAL',
      'OBITO',
      'PRE-CANCELADO',
      'PRE-CONTEMPLADO',
      'PRE-REATIVADO',
    ],

    /* data table */
    contractList: [],
    noDataText: 'Nenhum registro encontrado',
    heightDinamic: '400px',
    loading: true,
    headers: [
      { text: 'REFERÊNCIA', value: 'reference' },
      { text: 'CLIENTE', value: 'client_name' },
      { text: 'TELEFONE', value: 'phone' },
      { text: 'PLANO', value: 'sales_plan' },
      { text: 'GRUPO', value: 'group_cota_r_d' },
      { text: 'VENCTO', value: 'due_day' },
      { text: 'MODELO', value: 'vehicle_model' },
      { text: 'STATUS', value: 'contract_status' },
      { text: 'OPÇÕES', value: 'actions', align: 'end' },
    ],

    /* router */
    routeOptions: {
      resource: 'index',
      method: 'get',
      params: {},
    },

    /* pagination */
    pageOptions: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
    },

    /* modal */
    loadModal: false,
    modal: false,
    idContract: '',
    contractItem: {},
  }),

  watch: {
    //
  },

  created() {
    this.initialize()
    this.getItemsListVehicles()
  },

  methods: {
    async getItemsListVehicles() {
      await axiosIns.get('api/v1/integration/vehicle_model/index')
        .then(res => {
          this.listVehicles.push(res.data.data)
        })
    },
    async initialize() {
      await axiosIns
        .get('/api/v1/integration/contract/index')
        .then(res => {
          this.heightDinamic = `${window.screen.height * 0.45}px`
          res.data.data.map(value => {
            this.contractList.push({
              id: value.id,
              total: value.total,
              reference: value.reference,
              client_name: value.client_name,
              sales_plan: value.sales_plan,
              group_cota_r_d: `${value.group_number} ${value.quota_number} ${value.reposition} ${value.quota_digit}`,
              due_day: value.due_day,
              phone: value.phone,
              vehicle_model: value.vehicle_model,
              contract_status: value.contract_status,
            })
          })
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    async updatePage() {
      this.loading = true
      this.contractList = []
      if (this.routeOptions.method === 'get') {
        await axiosIns
          .get(`/api/v1/integration/contract/${this.routeOptions.resource}?page=${this.pageOptions.page}`)
          .then(res => {
            res.data.data.map(value => {
              this.contractList.push({
                id: value.id,
                total: value.total,
                reference: value.reference,
                client_name: value.client_name,
                sales_plan: value.sales_plan,
                group_cota_r_d: `${value.group_number} ${value.quota_number} ${value.reposition} ${value.quota_digit}`,
                due_day: value.due_day,
                phone: value.phone,
                vehicle_model: value.vehicle_model,
                contract_status: value.contract_status,
              })
            })
            this.pageOptions.itemsPerPage = res.data.meta.per_page
            this.pageOptions.pageCount = res.data.meta.last_page
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.noDataText = `Contate o suporte do sistema: ${err}`
          })
      } else if (this.routeOptions.method === 'post') {
        await axiosIns
          .post(
            `/api/v1/integration/contract/${this.routeOptions.resource}?page=${this.pageOptions.page}`,
            this.routeOptions.params,
          )
          .then(res => {
            res.data.data.map(value => {
              this.contractList.push({
                id: value.id,
                total: value.total,
                reference: value.reference,
                client_name: value.client_name,
                sales_plan: value.sales_plan,
                group_cota_r_d: `${value.group_number} ${value.quota_number} ${value.reposition} ${value.quota_digit}`,
                due_day: value.due_day,
                phone: value.phone,
                vehicle_model: value.vehicle_model,
                contract_status: value.contract_status,
              })
            })
            this.pageOptions.itemsPerPage = res.data.meta.per_page
            this.pageOptions.pageCount = res.data.meta.last_page
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.noDataText = `Contate o suporte do sistema: ${err}`
          })
      }
    },

    openModal() {
      this.dialogFilter = true
    },

    async filterContract() {
      this.loading = true
      this.routeOptions = {
        resource: 'filter_contract',
        method: 'post',
        params: this.filterOptions,
      }
      this.contractList = []
      this.dialogFilter = false

      await axiosIns
        .post('/api/v1/integration/contract/filter_contract', this.routeOptions.params)
        .then(res => {
          res.data.data.map(value => {
            this.contractList.push({
              id: value.id,
              total: value.total,
              reference: value.reference,
              client_name: value.client_name,
              sales_plan: value.sales_plan,
              group_cota_r_d: `${value.group_number} ${value.quota_number} ${value.reposition} ${value.quota_digit}`,
              due_day: value.due_day,
              phone: value.phone,
              vehicle_model: value.vehicle_model,
              contract_status: value.contract_status,
            })
          })
          this.pageOptions.page = res.data.meta.current_page
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    async filterReset() {
      this.loading = true
      this.contractList = []
      Object.keys(this.filterOptions).forEach(key => {
        this.filterOptions[key] = ''
      })
      this.routeOptions = {
        resource: 'index',
        method: 'get',
        params: {},
      }

      this.initialize()
    },

    chatWhatsapp(phone) {
      window.open(`https://wa.me/55${phone}`, '_blank')
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    formatType(type) {
      let typeString = ''
      if (type === 'R') typeString = 'REPOSIÇÃO'
      if (type === 'N') typeString = 'NOVO'

      return typeString
    },

    /* modal methods */
    async getContractInfo(item) {
      this.loadModal = item.id
      this.idContract = item.id
      await axiosIns
        .get(`/api/v1/integration/contract/edit/${item.id}`)
        .then(res => {
          const response = res.data.data
          this.contractItem = {
            client_name: response.client_name,
            cpf_cnpj: response.cpf_cnpj,
            phone: response.phone,
            phone_2: response.phone_2,
            vendor_name: response.vendor_name,
            contract_type: this.formatType(response.contract_type),
            contract_status: response.contract_status,
            group_cota_r_d: `${response.group_number} ${response.quota_number} ${response.reposition} ${response.quota_digit}`,
            amortized_percentage: response.amortized_percentage,
            delay_percentage: response.delay_percentage,
            parcel_number: response.parcel_number,
            date_bordero: this.formatDate(response.date_bordero),
            parcel_last_date: this.formatDate(response.parcel_last_date),
            sales_plan: response.sales_plan,
            proposal_number: response.proposal_number,
            credit_value: response.credit_value,
            due_day: response.due_day,
            vehicle_model: response.vehicle_model,
          }
          this.modal = true
        })
        .catch(err => {
          this.modal = false
        })
      this.loadModal = false
    },
  },
}
</script>
