<template>
  <v-card>
    <v-card-title>
      Informações do Contrato
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title>Cliente</v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.client_name"
                    dense
                    readonly
                    outlined
                    label="Nome"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.cpf_cnpj"
                    dense
                    readonly
                    outlined
                    label="CPF/CNPJ"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.phone"
                    dense
                    readonly
                    outlined
                    label="Telefone"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.phone_2"
                    dense
                    readonly
                    outlined
                    label="Telefone 2"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title>Contrato</v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.proposal_number"
                    dense
                    readonly
                    outlined
                    label="Proposta"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.group_cota_r_d"
                    dense
                    readonly
                    outlined
                    label="Grupo"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.vehicle_model"
                    dense
                    readonly
                    outlined
                    label="Modelo"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.credit_value"
                    dense
                    readonly
                    outlined
                    label="Crédito"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.contract_status"
                    dense
                    readonly
                    outlined
                    label="Situação"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.contract_type"
                    dense
                    readonly
                    outlined
                    label="Tipo"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.amortized_percentage"
                    dense
                    readonly
                    outlined
                    label="% Amortizado"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.delay_percentage"
                    dense
                    readonly
                    outlined
                    label="% Atraso"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.sales_plan"
                    dense
                    readonly
                    outlined
                    label="Plano"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.parcel_number"
                    dense
                    readonly
                    outlined
                    label="Prazo"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.date_bordero"
                    dense
                    readonly
                    outlined
                    label="Data da Venda"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.due_day"
                    dense
                    readonly
                    outlined
                    label="Vencimento"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.vendor_name"
                    dense
                    readonly
                    outlined
                    label="Vendedor"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contract.parcel_last_date"
                    dense
                    readonly
                    outlined
                    label="Última Parcela"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    idContract: {
      type: String,
      default: '',
    },
    contract: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
